<template>
 <div class="min-h-screen flex flex-col bg-white">
  <main
   class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8"
  >
   <div class="flex-shrink-0 flex justify-center">
    <a href="/" class="inline-flex">
     <span class="sr-only">Workflow</span>
     <img class="h-36 w-auto" :src="logo" alt="" />
    </a>
   </div>
   <div class="py-10">
    <div class="text-center">
     <p class="text-base font-semibold encom_pink_text uppercase tracking-wide">
      {{ this.status ? this.status : 404 }} error
     </p>
     <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
      {{ this.status }}
      {{
       this.status == 404
        ? "Page not found."
        : this.status == 401
        ? "Unauthorized Action."
        : this.status == 403
        ? "Not Authorized"
        : "Page not found."
      }}
     </h1>
     <p class="mt-2 text-base text-gray-500">
      {{
       this.status == 404
        ? $t("pageNotFound")
        : this.status == 401
        ? "You was logged in with an unauthorized user."
        : this.status == 403
        ? $t("notAuthorized")
        : $t("pageNotFound")
      }}
     </p>
     <p class="mt-2 text-base text-gray-500" v-show="this.msg">
      Error message: <b>{{ this.msg }}</b>
     </p>
     <div class="mt-6" v-if="this.status == 404 || this.status == 403 || !this.status">
      <router-link to="/" class="text-base font-medium encom_primary_text"
       >{{ $t("goBackHome") }}<span aria-hidden="true"> &rarr;</span></router-link
      >
     </div>
     <div class="mt-6" v-else-if="this.status == 401">
      <a
       href="javascript:void(0)"
       @click.prevent="signOut"
       class="text-base font-medium encom_primary_text"
       >Log out<span aria-hidden="true"> &rarr;</span></a
      >
     </div>
    </div>
   </div>
  </main>
 </div>
</template>

<script>
const logo = localStorage.getItem("logo");
export default {
 name: "Error404",
 props: ["msg", "status"],
 data() {
  return {
   logo,
  };
 },
 methods: {
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   this.$store.dispatch("user", {});
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
 },
 mounted() {
  this.$emit("setLoading", false);
 },
};
</script>

<style></style>
